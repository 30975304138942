import React from 'react';
import '../styles/components/MarqueeText.scss';

const MarqueeText = ({ texts }) => {
  const repeatedTexts = [...texts, ...texts];

  return (
    <div className="MarqueeText container-fluid">
      <div className="row">
        <div className="MarqueeText__content col-12">
          {repeatedTexts.map((text, index) => (
            <a href="/productos/todos" key={index}>
              <h2 className="MarqueeText__content--text">
                {text}
              </h2>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MarqueeText;
