// src/components/QuotationForm.js
import React, { useState } from 'react';
import Button from './Button';
import { login } from '../services/authService';
import validateQuotationForm from '../utils/validateQuotationForm';
import '../styles/components/QuotationForm.scss';

const API_URL = process.env.REACT_APP_API_URL;

const QuotationForm = ({ cartItems, onBack }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    company: '',
    nit: '',
    address: '',
    budget: '',
    note: '',
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === 'phone' || name === 'nit') {
      formattedValue = value.replace(/\D/g, ''); 
    } else if (name === 'budget') {
      formattedValue = value.replace(/[^\d]/g, ''); // Solo números
    }

    setFormData({ ...formData, [name]: formattedValue });
    if (errors[name]) setErrors({ ...errors, [name]: '' });
  };

  const handleBudgetBlur = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      budget: new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
      }).format(Number(prevFormData.budget.replace(/\D/g, ''))),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateQuotationForm(formData);
    setErrors(formErrors);
    if (Object.keys(formErrors).length > 0) return;

    const sanitizedBudget = parseInt(formData.budget.replace(/[^0-9]/g, ''), 10);

    try {
      const token = await login();
      const response = await fetch(`${API_URL}/quotation/send-quotation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
        body: JSON.stringify({
          customerData: { ...formData, budget: sanitizedBudget },
          cartItems,
        })
      });

      if (!response.ok) {
        throw new Error(`Error en el servidor: ${response.statusText}`);
      }

      setIsSubmitted(true);
    } catch (error) {
      console.error('Error al enviar la cotización:', error);
      alert('Hubo un problema al enviar la cotización.');
    }
  };

  if (isSubmitted) {
    return (
      <div className="QuotationForm__success">
        <h3>¡Cotización enviada con éxito!</h3>
        <p>Gracias por tu interés. Nos pondremos en contacto contigo pronto.</p>
        <a
        href="/categorias"
        className="btn btn-primary">
          Volver al catálogo
        </a>
      </div>
    );
  }

  return (
    <div className="QuotationForm">
      <form onSubmit={handleSubmit}>
        <label>
          Nombre:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleFormChange}
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </label>

        <label>
          Teléfono:
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleFormChange}
            placeholder="Solo números"
          />
          {errors.phone && <span className="error">{errors.phone}</span>}
        </label>

        <label>
          Correo Electrónico:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleFormChange}
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </label>

        <label>
          Empresa:
          <input
            type="text"
            name="company"
            value={formData.company}
            onChange={handleFormChange}
          />
          {errors.company && <span className="error">{errors.company}</span>}
        </label>

        <label>
          NIT o Cédula (Opcional):
          <input
            type="text"
            name="nit"
            value={formData.nit}
            onChange={handleFormChange}
            placeholder="Solo números"
          />
        </label>

        <label>
          Dirección (Opcional):
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleFormChange}
          />
        </label>

        <label>
          Presupuesto (COP):
          <input
            type="text"
            name="budget"
            value={formData.budget}
            onChange={handleFormChange}
            onBlur={handleBudgetBlur}
            placeholder="Solo números"
          />
          {errors.budget && <span className="error">{errors.budget}</span>}
        </label>

        <label>
          Nota (Opcional):
          <textarea
            name="note"
            value={formData.note}
            onChange={handleFormChange}
          ></textarea>
        </label>

        <div className="QuotationForm__buttons">
          <Button text="Atrás" onClick={onBack} />
          <Button text="Enviar Cotización" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default QuotationForm;
