import { useState, useEffect } from 'react';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('admToken');
    if (token) {
      setIsAuthenticated(true);
      const userData = localStorage.getItem('user');
      if(userData) {
        try {
          setUser(userData);
        } catch (error) {
          console.error('User cannot find');
        }
      } else {
        console.warn('No user data found in localStorage');
      }      
    }
  }, []);

  const logout = () => {
    localStorage.removeItem('admToken');
    localStorage.removeItem('user');
    setIsAuthenticated(false);
    setUser(null);
    window.location.reload();
  };

  return { isAuthenticated, user, logout };
};

export default useAuth;
