import React, { useState } from 'react';
import '../styles/components/HeaderResponsive.scss';
import { FaBars } from "react-icons/fa";
import { useCart } from '../context/CartContext'; 

const HeaderResponsive = ({ logo, userIcon, shopIcon }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [expandedSubcategory, setExpandedSubcategory] = useState(null);
  const { toggleCart } = useCart();

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCategoryClick = (categoryName) => {
    setActiveCategory(categoryName === activeCategory ? null : categoryName);
    setExpandedSubcategory(null); 
  };

  const handleSubcategoryToggle = (subcategoryTitle) => {
    setExpandedSubcategory(subcategoryTitle === expandedSubcategory ? null : subcategoryTitle);
  };

  const categories = [
    {
      name: 'Todo para el hogar',
      multiColumn:true,
      subcategories: [
        {
          title: 'Hogar',
          links: [
            { name: 'Artículos Cocina', link: '/categorias/hogar/articulos-cocina' },
            { name: 'Artículos Hogar', link: '/categorias/hogar/articulos-hogar' },
            { name: 'Artículos para vinos', link: '/categorias/hogar/articulos-para-vinos' },
            { name: 'Bar', link: '/categorias/hogar/bar' },
            { name: 'Cobijas', link: '/categorias/hogar/cobijas' },
            { name: 'Contenedores y portacomidas', link: '/categorias/hogar/contenedores-y-portacomidas' },
            { name: 'Destapadores', link: '/categorias/hogar/destapadores' },
            { name: 'Fondue', link: '/categorias/hogar/fondue' },
            { name: 'Raclette', link: '/categorias/hogar/raclette' },
            { name: 'Set de BBQ', link: '/categorias/hogar/set-de-bbq' },
            { name: 'Set de quesos', link: '/categorias/hogar/set-de-quesos' },
            { name: 'Todos', link: '/categorias/hogar' }
          ]
        },
        {
          title: 'Herramientas',
          links: [
            { name: 'Candados', link: '/categorias/herramientas/candados' },
            { name: 'Metros', link: '/categorias/herramientas/metros' },
            { name: 'Set herramientas', link: '/categorias/herramientas/set-herramientas' },
            { name: 'Automóvil', link: '/categorias/herramientas/automovil' },
            { name: 'Herramientero con linterna', link: '/categorias/herramientas/herramientero-con-linterna' },
            { name: 'Multiusos', link: '/categorias/herramientas/multiusos' },
            { name: 'Todos', link: '/categorias/herramientas' },
          ]
        },
        {
          title: 'Iluminación',
          links: [
            { name: 'Metálicas', link: '/categorias/iluminacion/metalicas' },
            { name: 'Linternas llavero', link: '/categorias/iluminacion/linternas-llavero' },
            { name: 'Plásticas', link: '/categorias/iluminacion/plasticas' },
            { name: 'Láser y apuntadores', link: '/categorias/iluminacion/laser-y-apuntadores' },
            { name: 'Todos', link: '/categorias/iluminacion' }
          ]
        }
      ]
    },
    {
      name: 'Variedades',
      multiColumn:true,
      subcategories: [
        {
          title: 'Artículos de escritura',
          links: [
            { name: 'Lápices', link: '/categorias/articulos-de-escritura/lapices' },
            { name: 'Estuches bolígrafos', link: '/categorias/articulos-de-escritura/estuches-boligrafos' },
            { name: 'Metálicos', link: '/categorias/articulos-de-escritura/metalicos' },
            { name: 'Portaminas', link: '/categorias/articulos-de-escritura/portaminas' },
            { name: 'Ecológicos', link: '/categorias/articulos-de-escritura/ecologicos' },
            { name: 'Metálicos con stylus', link: '/categorias/articulos-de-escritura/metalicos-con-stylus' },
            { name: 'Plásticos con stylus', link: '/categorias/articulos-de-escritura/plasticos-con-stylus' },
            { name: 'Clip Metalico', link: '/categorias/articulos-de-escritura/clip-metalico' },
            { name: 'Todos', link: '/categorias/articulos-de-escritura' }
          ]
        },
        {
          title: 'Mugs y botilitos',
          links: [
            { name: 'Botilitos deportivos', link: '/categorias/mugs-botilitos-vasos-y-termos/botilitos-deportivos' },
            { name: 'Botilitos metálicos', link: '/categorias/mugs-botilitos-vasos-y-termos/mug-metalicos' },
            { name: 'Botilitos plasticos', link: '/categorias/mugs-botilitos-vasos-y-termos/botilitos-plasticos' },
            { name: 'Botilitos policarbonato', link: '/categorias/mugs-botilitos-vasos-y-termos/botilitos-tritan-y-policarbonato' },
            { name: 'Mug cerámica', link: '/categorias/mugs-botilitos-vasos-y-termos/mug-ceramica' },
            { name: 'Mug metálicos', link: '/categorias/mugs-botilitos-vasos-y-termos/mug-metalicos' },
            { name: 'Mug plásticos', link: '/categorias/mugs-botilitos-vasos-y-termos/botilitos-plasticos' },
            { name: 'Mug sublimación', link: '/categorias/mugs-botilitos-vasos-y-termos/mug-sublimacion' },
            { name: 'Termos', link: '/categorias/mugs-botilitos-vasos-y-termos/termos' },
            { name: 'Vasos y copas', link: '/categorias/mugs-botilitos-vasos-y-termos/vasos-y-copas' },
            { name: 'Aislamiento térmico', link: '/categorias/mugs-botilitos-vasos-y-termos/aislamiento-termico-vacuum' },
          ]
        },
        {
          title: 'Paraguas e impermeables',
          links: [
            { name: 'Lujo', link: '/categorias/paraguas-e-impermeables/lujo' },
            { name: 'Impermeables y ponchos', link: '/categorias/paraguas-e-impermeables/impermeables-y-ponchos' },
            { name: 'Mini 21 Pulgadas', link: '/categorias/paraguas-e-impermeables/mini-21-pulgadas' },
            { name: 'Normales 23 a 30 pulgadas', link: '/categorias/paraguas-e-impermeables/normales-23-a-30-pulgadas' },
            { name: 'Todos', link: '/categorias/paraguas-e-impermeables' },
          ]
        },
        {
          title: 'Tecnología',
          links: [
            { name: 'Artículos Smartphone, Tablet y Computador', link: '/categorias/tecnologia/articulos-smartphone-tablet-y-computador' },
            { name: 'Adaptadores', link: '/categorias/tecnologia/adaptadores' },
            { name: 'Speaker y parlantes', link: '/categorias/tecnologia/speaker-y-parlantes' },
            { name: 'Cargadores y Pilas recargables', link: '/categorias/tecnologia/cargadores-y-pilas-recargables' },
            { name: 'Audífonos', link: '/categorias/tecnologia/audifonos' },
            { name: 'Organizadores de Cablesos', link: 'categorias/tecnologia/organizadores-de-cables' },
            { name: 'Selfie Sticks (Monopod)', link: '/categorias/tecnologia/selfie-sticks-monopod' },
            { name: 'USB Memorias', link: '/categorias/tecnologia/usb-memorias' },
            { name: 'Todos', link: '/categorias/tecnologia' }
          ]
        },
        {
          title: 'Oficina',
          links: [
            { name: 'Stickies y sets', link: '/categorias/oficina/stickies-y-sets' },
            { name: 'Resaltadores', link: '/categorias/oficina/resaltadores' },
            { name: 'Portalápices', link: '/categorias/oficina/portalapices' },
            { name: 'Artículos Escritorio', link: '/categorias/oficina/articulos-escritorio' },
            { name: 'Libretas', link: '/categorias/oficina/libretas' },
            { name: 'Portadocumentos y Portafolios', link: '/categorias/oficina/portadocumentos-y-portafolios' },
            { name: 'Ecológicos', link: '/categorias/oficina/ecologicos' },
            { name: 'Mouse-Pad', link: '/categorias/oficina/mouse-pad' },
            { name: 'Tarjeteros', link: '/categorias/oficina/tarjeteros' },
            { name: 'Producción nacional', link: '/categorias/oficina/produccion-nacional' },
            { name: 'Memo-stick', link: '/categorias/oficina/memo-stick' },
            { name: 'Todos', link: '/categorias/oficina' }            
          ]
        },
      ]
    },
    {
      name: 'Novedades',
      subcategories: [
        {
          title: 'Descubre el universo en-stock',
          links: [
            { name: 'Todos', link: '/categorias/eco-nature' }
          ]
        }
      ]
    },
    {
      name: 'Productos Ecológicos',
      multiColumn:false,
      subcategories: [
        {
          title: 'Econature',
          links: [
            { name: 'Todos', link: '/categorias/eco-nature' }
          ]
        },
        {
          title: 'Ecología - Ecopromo',
          links: [
            { name: 'Todos', link: '/categorias/ecologia-ecopromo' }
          ]
        }
      ]
    }
  ];

  return (
    <div className="header-responsive">
      <div className="header-responsive__wrapper">
        <button className="header__hamburger" onClick={toggleMenu}>
          <FaBars className="header__hamburger-icon" /> 
        </button>
        <a 
          className="header__logo--responsive"
          href="/">
            <img 
              src={logo} 
              alt="Logo promocionales en-stock"
            />
        </a>
        <a onClick={toggleCart}>
          <img
            className="contact-icon"
            src={shopIcon}
            alt="Icono Carrito de compras"
          />
        </a>
      </div>

      <div className={`header__sidebar ${isMenuOpen ? 'open' : ''}`}>
        <button className="header__sidebar-close" onClick={toggleMenu}>X</button>
        <ul className="header__mobile-menu-list">
          {categories.map((category, index) => (
            <li key={index} className="header__mobile-menu-item">
              <div onClick={() => handleCategoryClick(category.name)}>
                {category.name} <span>{activeCategory === category.name ? '-' : '>'}</span>
              </div>
              {activeCategory === category.name && (
                <ul className="header__mobile-submenu">
                  {category.subcategories.map((subcategory, subIndex) => (
                    <li key={subIndex} className="header__mobile-submenu-item">
                      <div onClick={() => handleSubcategoryToggle(subcategory.title)}>
                        {subcategory.title} <span>{expandedSubcategory === subcategory.title ? '-' : '+'}</span>
                      </div>
                      {expandedSubcategory === subcategory.title && (
                        <ul className="header__mobile-subsubmenu">
                          {subcategory.links.map((link, linkIndex) => (
                            <li key={linkIndex}>
                              <a href={link.link}>{link.name}</a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
        <a
          href="/categorias"
          class="btn btn-primary high-res">
            Categorias
        </a>
      </div>
      {isMenuOpen && <div className="header__overlay" onClick={toggleMenu}></div>}
    </div>
  );
};

export default HeaderResponsive;
