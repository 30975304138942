

import React from 'react';
import image from '../assets/slider/quality_badget.webp';
import '../styles/components/SimpleBanner.scss';


const SimpleBanner = ({totalProducts, totalColors}) => {
 
  return (
    <div className="SimpleBanner container-fluid">
      <div className="SimpleBanner__text">
        <p className="SimpleBanner__subtitle">
        La plataforma de promocionales más grande del país.
        </p>
        <h2 className="SimpleBanner__title">
          ¡Lleva tu marca al siguiente nivel con calidad garantizada!
        </h2>
        <p className="SimpleBanner__stats">
          Contamos con <strong>{totalProducts}</strong> productos y{' '}
          <strong>{totalColors}</strong>  listas para personalizar, diseñados 
          con los más altos estándares de calidad.
          <br />
          ¡Descubre cómo hacer que tu marca brille con lo mejor en promocionales!
        </p>
        <a href="/productos/todos" className="btn btn-primary">
          Ver todos los productos
        </a>
      </div>
      <div className="SimpleBanner__image">
        <img
          src={image}
          alt="La bodega de promocionales más grande del país."
          className="masonry__image"
          loading="lazy"
        />
      </div>
    </div>
  );
};


export default SimpleBanner;