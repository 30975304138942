import React from 'react';
import '../styles/components/ProductPackagingInfo.scss';

const ProductPackagingInfo = ({ unitsPerPackage, packageDetails, warehouse }) => {
  // Verifica si packageDetails tiene la estructura de CDO promocionales
  const isCDOPackaging =
    packageDetails &&
    packageDetails.volume &&
    packageDetails.weight &&
    packageDetails.measures &&
    packageDetails.quantity;

  // Verifica si es Marpico (suponemos que la estructura cleanPackage de Marpico incluye la llave "Marca")
  const isMarpicoPackaging = warehouse === 'Marpico' && packageDetails && packageDetails.Marca;

  // Nuevo caso: si es Boton Promo, se muestra la información organizada
  const isBotonPromo = warehouse === 'Boton Promo' && packageDetails && Object.keys(packageDetails).length > 0;

  return (
    <div className="ProductPackagingInfo">
      {unitsPerPackage && (
        <div className="ProductPackagingInfo__row">
          <span className="ProductPackagingInfo__label">
            Unidades por Caja:
          </span>
          <span className="ProductPackagingInfo__value">
            {unitsPerPackage} Unidades
          </span>
        </div>
      )}
      <div className="ProductPackagingInfo__row">
        <span className="info__title">
          Sobre el producto:
        </span>
        <span className="ProductPackagingInfo__value">
          {isCDOPackaging ? (
            <div className="ProductPackagingInfo">
              <div>
                <strong>{packageDetails.measures.label}</strong> {packageDetails.measures.value}
              </div>
              <div>
                <strong>{packageDetails.volume.label}</strong> {packageDetails.volume.value}
              </div>
              <div>
                <strong>{packageDetails.weight.label}</strong> {packageDetails.weight.value}
              </div>
              <div>
                <strong>{packageDetails.quantity.label}</strong> {packageDetails.quantity.value}
              </div>
            </div>
          ) : isMarpicoPackaging ? (
            <div className="ProductPackagingInfo">
              {Object.entries(packageDetails).map(([key, value]) => (
                <div key={key} className="ProductPackagingInfo__detail">
                  <strong>{key}:</strong> {value}
                </div>
              ))}
            </div>
          ) : isBotonPromo ? (
            <div className="ProductPackagingInfo">
              {Object.entries(packageDetails).map(([key, value]) => (
                <div key={key} className="ProductPackagingInfo__detail">
                  <strong>{key}:</strong> {value}
                </div>
              ))}
            </div>
          ) : (
            <>
              {packageDetails && packageDetails.dimensions ? packageDetails.dimensions : ''} /{' '}
              {packageDetails && packageDetails.weight ? packageDetails.weight : ''}
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default ProductPackagingInfo;
