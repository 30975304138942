import React, { useState } from 'react';
import useAuth from '../utils/useAuth';
import { useCart } from '../context/CartContext'; 

import Megamenu from '../components/Megamenu';
import SearchBar from '../components/SearchBar';

const HeaderDesktop = ({ logo, userIcon, shopIcon, wpIcon, emailIcon }) => {
  const { isAuthenticated, user, logout } = useAuth();
  const { toggleCart } = useCart();

  return (
    <div className="header__desktop container-fluid">
      <div className="header__top-row row">
        <div className="header__logo-container col-sm-2">
          <a href="/">
            <img 
              className="header__logo" 
              src={logo} 
              alt="Logo promocionales en-stock"
            />
          </a>
        </div>
        <div className="header__search-bar col-sm-3">
          <SearchBar />
        </div>
        <div className="header__contact col-sm-2">
          <div className="header__contact-head">
            <img
              className="contact-icon"
              src={emailIcon}
              alt="Icono email"
            />
            <span class="primary-color bolded">
              Correo cotizaciones
            </span>
          </div>
          <div className="header__contact-content">
            <a href="mailto:ventas@en-stock.com">
              ventas@en-stock.com
            </a>
          </div>
        </div>
        <div className="header__contact col-sm-3">
          <div className="header__contact-head">
            <img
              className="contact-icon"
              src={wpIcon}
              alt="Icono whatsapp"
            />
            <span class="primary-color bolded">
              Teléfonos contacto
            </span>
          </div>
          <div className="header__contact-content dual">
            <a href="tel:3028593318">
              302 859 3318
            </a>
               -   
            <a href="tel:3005572000">
                300 557 2000
            </a>
          </div>
        </div> 
        <div className="header__icons col-sm-1">
          {isAuthenticated && (
            <div className="header__user-info">
              <span>Bienvenido, {user}!</span>
              <button onClick={logout} className="header__logout-btn">
                Cerrar sesión
              </button>
            </div>
          )}
          <a onClick={toggleCart}>
            <img
              className="contact-icon"
              src={shopIcon}
              alt="Icono Carrito de compras"
            />
          </a>
        </div> 
      </div>

      <div className="container-fluid">
        <div className="header__bottom-row row">
          <div className="header__menu-container col-12">
            <nav className="header__menu">
              <Megamenu />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderDesktop;
