import React, { useState } from 'react';
import Slider from 'react-slick';
import '../styles/components/HeroSlider.scss';
import SliderOne from '../assets/slider/slider_1.webp';
import SliderTwo from '../assets/slider/slider_2.webp';
import SliderThree from '../assets/slider/slider_3.webp';
import SliderFourth from '../assets/slider/slider_4.webp';
import SliderOneMobile from '../assets/slider/slider_responsive_1.webp';
import SliderTwoMobile from '../assets/slider/slider_responsive_2.webp';
import SliderThreeMobile from '../assets/slider/slider_responsive_3.webp';
import SliderFourthMobile from '../assets/slider/slider_responsive_4.webp';
import { PrevArrow, NextArrow } from '../components/CustomArrows';

const HeroSlider = () => {
  const sliderDuration = 5000;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: sliderDuration,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="hero-slider container-fluid">
      <Slider {...settings}>
        <div className="hero-slider__slide">
          <picture>
            <source media="(min-width:768px)" srcSet={SliderOne} />
            <img
              src={SliderOneMobile}
              alt="Promocionales en-stock"
              className="hero-slider__image"
              loading="lazy"
            />
          </picture>
          <div className="slide-content">
            <h1 class="h1">Marca tu empresa con estilo</h1>
            <p>Productos promocionales con tu logo, perfectos para destacar.</p>
            <a href="/productos/todos" className="btn btn-primary">
              ¡Personaliza ahora!
            </a>
          </div>
        </div>
        <div className="hero-slider__slide">
          <picture>
            <source media="(min-width:768px)" srcSet={SliderTwo} />
            <img
              src={SliderTwoMobile}
              alt="Promocionales en-stock"
              className="hero-slider__image"
              loading="lazy"
            />
          </picture>
          <div className="slide-content">
            <h2 class="h1">+4000 productos para elegir</h2>
            <p>Encuentra lo que necesitas con la mejor calidad y variedad.</p>
            <a href="/productos/todos" className="btn btn-primary">
              Explora ahora
            </a>
          </div>
        </div>
        <div className="hero-slider__slide">
          <picture>
            <source media="(min-width:768px)" srcSet={SliderThree} />
            <img
              src={SliderThreeMobile}
              alt="Promocionales en-stock"
              className="hero-slider__image"
              loading="lazy"
            />
          </picture>
          <div className="slide-content">
            <h2 className="h1">Promoción y sostenibilidad</h2>
            <p>Artículos responsables con el medio ambiente.</p>
            <a href="/categorias/ecologia-ecopromo" className="btn btn-primary">
              Descubre más
            </a>
          </div>
        </div>
        <div className="hero-slider__slide">
          <picture>
            <source media="(min-width:768px)" srcSet={SliderFourth} />
            <img
              src={SliderFourthMobile}
              alt="Promocionales en-stock"
              className="hero-slider__image"
              loading="lazy"
            />
          </picture>
          <div className="slide-content">
            <h2 className="h1">Entrega rápida y segura</h2>
            <p>Recibe tus productos en la puerta de tu casa o negocio.</p>
            <a href="/categorias" className="btn btn-primary">
              Compra hoy
            </a>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default HeroSlider;
