import React, { useState, useEffect } from 'react';
import { useCart } from '../context/CartContext';
import Button from './Button';
import QuotationForm from './QuotationForm';
import '../styles/components/SideCart.scss';
import closeIcon from '../assets/icons/close.svg';
import basketIcon from '../assets/icons/basket.svg';
import emptytIcon from '../assets/icons/empty.svg';

const SideCart = () => {
  const { cartItems, isCartOpen, toggleCart, removeFromCart, updateQuantity } = useCart();
  const [step, setStep] = useState(1);
  const [images, setImages] = useState({});
  // images: { [reference]: thumbnailMiniUrl | null }

  const handleNextStep = () => setStep(2);
  const handlePreviousStep = () => setStep(1);

  useEffect(() => {
    if (isCartOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isCartOpen]);

  // Efecto para cargar las imágenes thumbnails_mini de los productos del carrito
  useEffect(() => {
    if (cartItems.length > 0) {
      const newImages = {};
      const BASE_URL = process.env.REACT_APP_S3_BASE_URL;
      
      cartItems.forEach((item) => {
        if (item.reference) {
          const totalImages = item.image_count || 10; // Valor predeterminado: 10
          // Si el producto pertenece a "PromoOpcion", se reemplazan los espacios en la referencia por underscores.
          const fixedReference = item.warehouse === 'PromoOpcion'
            ? item.reference.replace(/\s+/g, '_')
            : item.reference;
          newImages[item.reference] = `${BASE_URL}/thumbnails_mini/_ref_${fixedReference}_img_1_of_${totalImages}.jpg`;
        }
      });

      setImages(newImages);
    }
  }, [cartItems]);

  return (
    <>
      {/* Overlay */}
      <div className={`SideCart__overlay ${isCartOpen ? 'active' : ''}`} onClick={toggleCart}></div>

      <div className={`SideCart ${isCartOpen ? 'open' : ''}`}>
        <div className="SideCart__header">
          <h2>Cesta de artículos</h2>
          <button className="SideCart__close-button" onClick={toggleCart}>
            <img src={closeIcon} alt="Cerrar" className="SideCart__close-icon" />
          </button>
        </div>

        {step === 1 ? (
          <>
            {cartItems.length === 0 ? (
              <div className="SideCart__empty">
                <img 
                  src={emptytIcon} 
                  alt="Carrito de cotización vacío" 
                  className="SideCart__remove-icon" 
                />
                <h3>Cesta vacía</h3>
                <Button
                  text="Ver productos"
                  onClick={() => (window.location.href = '/categorias')}
                />
              </div>
            ) : (
              <div className="SideCart__content">
                {cartItems.map((item, index) => {
                  const thumbnailUrl = images[item.reference];

                  return (
                    <div key={index} className="SideCart__item">
                      {thumbnailUrl ? (
                        <img
                          src={thumbnailUrl}
                          alt={item.title}
                          className="SideCart__item-image"
                        />
                      ) : (
                        <div className="SideCart__item-image SideCart__item-image--placeholder">
                          {/* Aquí puedes mostrar un ícono o texto indicando que no hay imagen */}
                        </div>
                      )}
                      <div className="SideCart__item-details">
                        <h4>{item.title}</h4>
                      </div>
                      <div className="SideCart__quantity">
                        <button onClick={() => updateQuantity(item, item.quantity - 1)}>-</button>
                        <span>{item.quantity}</span>
                        <button onClick={() => updateQuantity(item, item.quantity + 1)}>+</button>
                      </div>
                      <button
                        className="SideCart__remove"
                        onClick={() => removeFromCart(item)}
                      >
                        <img src={basketIcon} alt="Eliminar" className="SideCart__remove-icon" />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
            {cartItems.length > 0 && (
              <div className="SideCart__footer">
                <Button
                  text="Cotizar artículos"
                  onClick={handleNextStep}
                  className="btn btn-primary"
                />
              </div>
            )}
          </>
        ) : (
          <QuotationForm cartItems={cartItems} onBack={handlePreviousStep} />
        )}
      </div>
    </>
  );
};

export default SideCart;
