import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ProductImageCarousel from '../components/ProductImageCarousel';
import VariationSelector from '../components/VariationSelector';
import QuantitySelector from '../components/QuantitySelector';
import Button from '../components/Button';
import ProductPackagingInfo from '../components/ProductPackagingInfo';
import ProductVariationsTable from '../components/ProductVariationsTable';
import Faq from '../components/Faq';
import ProductCarousel from '../components/ProductCarousel';
import { useCart } from '../context/CartContext';
import useAuth from '../utils/useAuth';

import '../styles/pages/ProductPage.scss';

const API_URL = process.env.REACT_APP_API_URL;
const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL || '';

/**
 * Genera las URLs de imágenes usando la convención establecida.
 * Si el warehouse es "PromoOpcion", se corrige la referencia reemplazando
 * espacios por guiones bajos.
 */
const generateImageUrls = (reference, totalImages = 10, warehouse) => {
  const fixedReference =
    warehouse === 'PromoOpcion' ? reference.replace(/\s+/g, '_') : reference;
  return {
    thumbnails: Array.from({ length: totalImages }, (_, i) =>
      `${S3_BASE_URL}/thumbnails/_ref_${fixedReference}_img_${i + 1}_of_${totalImages}.jpg`
    ),
    fullSize: Array.from({ length: totalImages }, (_, i) =>
      `${S3_BASE_URL}/_ref_${fixedReference}_img_${i + 1}_of_${totalImages}.jpg`
    ),
  };
};

const ProductPage = () => {
  const { slug } = useParams();
  const [product, setProduct] = useState(null);
  const [productImages, setProductImages] = useState({ thumbnails: [], fullSize: [] });
  const [loading, setLoading] = useState(true);
  const [imagesLoading, setImagesLoading] = useState(true);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const { isAuthenticated } = useAuth();
  const { addToCart } = useCart();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const token = localStorage.getItem('token');
        const { data: fetchedProduct } = await axios.get(`${API_URL}/products/${slug}`, {
          headers: { authorization: token },
        });
        setProduct(fetchedProduct);

        // Usar image_count o 10 como valor por defecto
        const imageCount = fetchedProduct.image_count || 10;
        // Generar URLs pasando también la bodega para corregir la referencia si es "PromoOpcion"
        const images = generateImageUrls(
          fetchedProduct.reference,
          imageCount,
          fetchedProduct.warehouse
        );
        setProductImages(images);
      } catch (error) {
        console.error('Error fetching product:', error);
      } finally {
        setLoading(false);
        setImagesLoading(false);
      }
    };

    fetchProduct();
  }, [slug]);

  if (loading) return <div>Loading...</div>;
  if (!product) return <div>Product not found</div>;
  if (imagesLoading) return <div>Loading images...</div>;

  // Verificamos si la bodega es "CDO promocionales"
  const isCDOPromocionales = product.warehouse === 'CDO promocionales';

  const handleVariationSelect = (variation) => setSelectedVariation(variation);
  const handleQuantityChange = (quantity) => setSelectedQuantity(quantity);
  const handleAddToCart = () => addToCart(product, selectedVariation, selectedQuantity);

  const faqs = [
    {
      question: '¿Cómo cuidar adecuadamente este producto?',
      answer: 'Para cuidar este producto, asegúrate de seguir las instrucciones específicas de lavado y mantenimiento.',
    },
    {
      question: '¿Cuánto tiempo tarda en llegar mi pedido?',
      answer: 'El tiempo de entrega varía según la ubicación, pero generalmente es entre 5 a 10 días hábiles.',
    },
    {
      question: '¿Puedo personalizar el producto?',
      answer: 'Sí, ofrecemos opciones de personalización. Por favor, contáctanos para más detalles.',
    },
    {
      question: '¿Qué métodos de pago aceptan?',
      answer: 'Aceptamos tarjetas de crédito, débito y otros métodos de pago electrónico.',
    },
  ];

  // Renderizamos la descripción usando dangerouslySetInnerHTML
  const productDescription = { __html: product.description };

  // Usamos la primera imagen de thumbnails o un placeholder para el encabezado
  const variationHeaderImage =
    productImages.thumbnails.length > 0
      ? productImages.thumbnails[0]
      : '/path/to/placeholder.jpg';

  return (
    <div className="ProductPage container">
      <div className="row">
        <div className="ProductPage__Gallery col-12 col-sm-12 col-lg-6">
          <ProductImageCarousel
            productReference={product.reference}
            thumbnails={productImages.thumbnails}
            fullSize={productImages.fullSize}
          />
        </div>
        <div className="ProductPage__Info col-12 col-sm-12 col-lg-6">
          <h1 className="ProductPage__title">{product.title}</h1>
          <span className="ProductPage__reference">{product.reference}</span>
          {isAuthenticated && (
            <p className="ProductCard__warehouse">
              <span className="ProductCard__warehouse--title">Bodega:</span>
              <span className="ProductCard__warehouse--value">
                <a href={product.warehouseUrl}>{product.warehouse}</a>
              </span>
            </p>
          )}
          <div className="ProductPage__details">
            <div className="ProductPage__description">
              <div dangerouslySetInnerHTML={productDescription} />
            </div>
            <div className="ProductPage__packinginfo">
            <ProductPackagingInfo
              unitsPerPackage={product.cleanUnitsPerPackage}
              packageDetails={product.cleanPackage}
              warehouse={product.warehouse}
            />
            </div>
            { ((product.warehouse === 'Boton Promo' || product.warehouse === 'Marpico') && Array.isArray(product.variations) && product.variations.length > 0) ||
            (!Array.isArray(product.variations) && Object.keys(product.variations).length > 0) ? (
              <div className="ProductPage__variations-list">
                <span 
                  className="info__title title__spacing">
                    Opciones disponibles:
                </span>
                <ul>
                {product.warehouse === 'Boton Promo' && Array.isArray(product.variations) ? (
                    product.variations.map((item, index) => (
                      <li key={index}>{item.Variedad}</li>
                    ))
                    ) : product.warehouse === 'Marpico' && Array.isArray(product.variations) ? (
                      product.variations.map((item, index) => (
                      <li key={item.Codigo || index}>
                        {item.Variedad.replace(/-/g, '').trim()}
                      </li>
                      ))
                    ) : (
                      Object.keys(product.variations).map((color) => (
                        <li key={color}>{color}</li>
                      ))
                    )}  
                </ul>
              </div>
            ) : null }
            <div className="ProductPage__quantity-selector">
              <QuantitySelector min={50} max={10000} onQuantityChange={handleQuantityChange} />
            </div>
            <div className="ProductPage__cta">
              <Button text="Solicitar una cotización" className="btn-primary" onClick={handleAddToCart} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="ProductPage__VariationsHeader col-12">
          <img src={variationHeaderImage} alt={product.title} className="ProductPage__Variations-image" />
          <h2 className="ProductPage__Variations-title">{product.title}</h2>
        </div>
        <div className="ProductPage__VariationsTable col-12">
          <ProductVariationsTable variations={product.variations} warehouse={product.warehouse} />
        </div>
      </div>
      <div className="row">
        <div className="ProductPage__FAQ col-12">
          <Faq faqs={faqs} />
        </div>
      </div>
      <div className="row">
        <ProductCarousel
          sectionTitle="Productos Recomendados"
          sectionSubtitle="Elige entre nuestra enorme colección personalizable de bolígrafos, vasos, botellas, tecnología y mucho más."
        />
      </div>
    </div>
  );
};

export default ProductPage;
