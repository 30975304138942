
import React from 'react';
import banner from '../assets/slider/slider_1.webp';
import '../styles/components/CategoryHeader.scss';

const CategoryHeader = ({title,subtitle}) => {
  return(
    <div
        className="CategoriesHeader"
        style={{
          backgroundImage: `url(${banner})`,
        }}
      >
        <div className="CategoriesHeader__content">
          <h1 className="CategoriesHeader__content--title">{title}</h1>
          <p className="CategoriesHeader__content-subtitle">
            {subtitle}
          </p>
        </div>
      </div>
  )
}

export default CategoryHeader;



