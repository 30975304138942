import React, { useState } from 'react';
import '../styles/components/Megamenu.scss';

import downArrow from '../assets/icons/downArrow.svg';
import nature from '../assets/icons/nature.svg';

const Megamenu = () => {
  const [activeCategory, setActiveCategory] = useState(null);

  const handleMouseEnter = (category) => {
    setActiveCategory(category);
  };

  const handleMouseLeave = () => {
    setActiveCategory(null);
  };

  // const categories = [
  //   {
  //     name: 'Todo para el hogar',
  //     multiColumn:true,
  //     subcategories: [
  //       {
  //         title: 'Hogar',
  //         links: [
  //           { name: 'Artículos Cocina', link: '/categorias/hogar/articulos-cocina' },
  //           { name: 'Artículos Hogar', link: '/categorias/hogar/articulos-hogar' },
  //           { name: 'Artículos para vinos', link: '/categorias/hogar/articulos-para-vinos' },
  //           { name: 'Bar', link: '/categorias/hogar/bar' },
  //           { name: 'Cobijas', link: '/categorias/hogar/cobijas' },
  //           { name: 'Contenedores y portacomidas', link: '/categorias/hogar/contenedores-y-portacomidas' },
  //           { name: 'Destapadores', link: '/categorias/hogar/destapadores' },
  //           { name: 'Fondue', link: '/categorias/hogar/fondue' },
  //           { name: 'Raclette', link: '/categorias/hogar/raclette' },
  //           { name: 'Set de BBQ', link: '/categorias/hogar/set-de-bbq' },
  //           { name: 'Set de quesos', link: '/categorias/hogar/set-de-quesos' },
  //           { name: 'Todos', link: '/categorias/hogar' }
  //         ]
  //       },
  //       {
  //         title: 'Herramientas',
  //         links: [
  //           { name: 'Candados', link: '/categorias/herramientas/candados' },
  //           { name: 'Metros', link: '/categorias/herramientas/metros' },
  //           { name: 'Set herramientas', link: '/categorias/herramientas/set-herramientas' },
  //           { name: 'Automóvil', link: '/categorias/herramientas/automovil' },
  //           { name: 'Herramientero con linterna', link: '/categorias/herramientas/herramientero-con-linterna' },
  //           { name: 'Multiusos', link: '/categorias/herramientas/multiusos' },
  //           { name: 'Todos', link: '/categorias/herramientas' },
  //         ]
  //       },
  //       {
  //         title: 'Iluminación',
  //         links: [
  //           { name: 'Metálicas', link: '/categorias/iluminacion/metalicas' },
  //           { name: 'Linternas llavero', link: '/categorias/iluminacion/linternas-llavero' },
  //           { name: 'Plásticas', link: '/categorias/iluminacion/plasticas' },
  //           { name: 'Láser y apuntadores', link: '/categorias/iluminacion/laser-y-apuntadores' },
  //           { name: 'Todos', link: '/categorias/iluminacion' }
  //         ]
  //       }
  //     ]
  //   },
  //   {
  //     name: 'Variedades',
  //     multiColumn:true,
  //     subcategories: [
  //       {
  //         title: 'Artículos de escritura',
  //         links: [
  //           { name: 'Lápices', link: '/categorias/articulos-de-escritura/lapices' },
  //           { name: 'Estuches bolígrafos', link: '/categorias/articulos-de-escritura/estuches-boligrafos' },
  //           { name: 'Metálicos', link: '/categorias/articulos-de-escritura/metalicos' },
  //           { name: 'Portaminas', link: '/categorias/articulos-de-escritura/portaminas' },
  //           { name: 'Ecológicos', link: '/categorias/articulos-de-escritura/ecologicos' },
  //           { name: 'Metálicos con stylus', link: '/categorias/articulos-de-escritura/metalicos-con-stylus' },
  //           { name: 'Plásticos con stylus', link: '/categorias/articulos-de-escritura/plasticos-con-stylus' },
  //           { name: 'Clip Metalico', link: '/categorias/articulos-de-escritura/clip-metalico' },
  //           { name: 'Todos', link: '/categorias/articulos-de-escritura' }
  //         ]
  //       },
  //       {
  //         title: 'Mugs y botilitos',
  //         links: [
  //           { name: 'Botilitos deportivos', link: '/categorias/mugs-botilitos-vasos-y-termos/botilitos-deportivos' },
  //           { name: 'Botilitos metálicos', link: '/categorias/mugs-botilitos-vasos-y-termos/mug-metalicos' },
  //           { name: 'Botilitos plasticos', link: '/categorias/mugs-botilitos-vasos-y-termos/botilitos-plasticos' },
  //           { name: 'Botilitos policarbonato', link: '/categorias/mugs-botilitos-vasos-y-termos/botilitos-tritan-y-policarbonato' },
  //           { name: 'Mug cerámica', link: '/categorias/mugs-botilitos-vasos-y-termos/mug-ceramica' },
  //           { name: 'Mug metálicos', link: '/categorias/mugs-botilitos-vasos-y-termos/mug-metalicos' },
  //           { name: 'Mug plásticos', link: '/categorias/mugs-botilitos-vasos-y-termos/botilitos-plasticos' },
  //           { name: 'Mug sublimación', link: '/categorias/mugs-botilitos-vasos-y-termos/mug-sublimacion' },
  //           { name: 'Termos', link: '/categorias/mugs-botilitos-vasos-y-termos/termos' },
  //           { name: 'Vasos y copas', link: '/categorias/mugs-botilitos-vasos-y-termos/vasos-y-copas' },
  //           { name: 'Aislamiento térmico', link: '/categorias/mugs-botilitos-vasos-y-termos/aislamiento-termico-vacuum' },
  //         ]
  //       },
  //       {
  //         title: 'Paraguas e impermeables',
  //         links: [
  //           { name: 'Lujo', link: '/categorias/paraguas-e-impermeables/lujo' },
  //           { name: 'Impermeables y ponchos', link: '/categorias/paraguas-e-impermeables/impermeables-y-ponchos' },
  //           { name: 'Mini 21 Pulgadas', link: '/categorias/paraguas-e-impermeables/mini-21-pulgadas' },
  //           { name: 'Normales 23 a 30 pulgadas', link: '/categorias/paraguas-e-impermeables/normales-23-a-30-pulgadas' },
  //           { name: 'Todos', link: '/categorias/paraguas-e-impermeables' },
  //         ]
  //       },
  //       {
  //         title: 'Tecnología',
  //         links: [
  //           { name: 'Artículos Smartphone, Tablet y Computador', link: '/categorias/tecnologia/articulos-smartphone-tablet-y-computador' },
  //           { name: 'Adaptadores', link: '/categorias/tecnologia/adaptadores' },
  //           { name: 'Speaker y parlantes', link: '/categorias/tecnologia/speaker-y-parlantes' },
  //           { name: 'Cargadores y Pilas recargables', link: '/categorias/tecnologia/cargadores-y-pilas-recargables' },
  //           { name: 'Audífonos', link: '/categorias/tecnologia/audifonos' },
  //           { name: 'Organizadores de Cablesos', link: 'categorias/tecnologia/organizadores-de-cables' },
  //           { name: 'Selfie Sticks (Monopod)', link: '/categorias/tecnologia/selfie-sticks-monopod' },
  //           { name: 'USB Memorias', link: '/categorias/tecnologia/usb-memorias' },
  //           { name: 'Todos', link: '/categorias/tecnologia' }
  //         ]
  //       },
  //       {
  //         title: 'Oficina',
  //         links: [
  //           { name: 'Stickies y sets', link: '/categorias/oficina/stickies-y-sets' },
  //           { name: 'Resaltadores', link: '/categorias/oficina/resaltadores' },
  //           { name: 'Portalápices', link: '/categorias/oficina/portalapices' },
  //           { name: 'Artículos Escritorio', link: '/categorias/oficina/articulos-escritorio' },
  //           { name: 'Libretas', link: '/categorias/oficina/libretas' },
  //           { name: 'Portadocumentos y Portafolios', link: '/categorias/oficina/portadocumentos-y-portafolios' },
  //           { name: 'Ecológicos', link: '/categorias/oficina/ecologicos' },
  //           { name: 'Mouse-Pad', link: '/categorias/oficina/mouse-pad' },
  //           { name: 'Tarjeteros', link: '/categorias/oficina/tarjeteros' },
  //           { name: 'Producción nacional', link: '/categorias/oficina/produccion-nacional' },
  //           { name: 'Memo-stick', link: '/categorias/oficina/memo-stick' },
  //           { name: 'Todos', link: '/categorias/oficina' }            
  //         ]
  //       },
  //     ]
  //   },
  //   {
  //     name: 'Novedades',
  //     subcategories: [
  //       {
  //         title: 'Descubre el universo en-stock',
  //         links: [
  //           { name: 'Todos', link: '/categorias/eco-nature' }
  //         ]
  //       }
  //     ]
  //   }
  // ];

  return (
    <div className="megamenu">
      {/* <ul className="megamenu__list">
        {categories.map((category, index) => (
          <li
            key={index}
            className={`megamenu__item ${activeCategory === category.name ? 'active' : ''}`}
            onMouseEnter={() => handleMouseEnter(category.name)}
            onMouseLeave={handleMouseLeave}
          >
            <span className="megamenu__link">
              {category.name}
              <img
                src={downArrow}
                alt="Arrow"
                className={`megamenu__arrow ${activeCategory === category.name ? 'up' : 'down'}`}
              />
            </span>
            <div
              className={`megamenu__dropdown ${category.multiColumn ? 'megamenu__columns' : ''}`}
            >
              {category.subcategories.map((group, groupIndex) => (
                <div key={groupIndex} className="megamenu__group">
                  <h4 className="megamenu__group-title">{group.title}</h4>
                  {group.links.map((link, linkIndex) => (
                    <a href={link.link} key={linkIndex} className="megamenu__dropdown-link">
                      {link.name}
                    </a>
                  ))}
                </div>
              ))}
            </div>
          </li>
        ))}
      </ul> */}
      <a
       href="/productos/todos"
       class="btn btn-primary highlighted mr-5">
        Todos los productos
      </a>
      <a
       href="/categorias/ecologia-ecopromo"
       class="btn btn-tertiary highlighted mr-5 btn-icon">
        <img
          src={nature}
          alt="Arrow"
          className="menu-icon"
        />
          Ecología - EcoPromo
      </a>
      <a
       href="/categorias"
       class="btn btn-primary highlighted green">
        Categorias y productos
      </a>
    </div>
  );

};

export default Megamenu;
