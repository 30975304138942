import React from 'react';
import '../styles/components/MultiRowIcon.scss';


const MultiRowIcon = ({ sectionTitle, sectionSubtitle, content }) => {
  return (
    <div className="RowIcon container-fluid">
      <svg class="RowIcon__wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fbf9f9" fill-opacity="1" d="M0,32L26.7,42.7C53.3,53,107,75,160,69.3C213.3,64,267,32,320,42.7C373.3,53,427,107,480,149.3C533.3,192,587,224,640,224C693.3,224,747,192,800,197.3C853.3,203,907,245,960,224C1013.3,203,1067,117,1120,106.7C1173.3,96,1227,160,1280,154.7C1333.3,149,1387,75,1413,37.3L1440,0L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"></path></svg>
      <h2 className="RowIcon__title">
        {sectionTitle}
      </h2>
      <p className="RowIcon__subtitle">
        {sectionSubtitle}
      </p>
      <div className="RowIcon__content row">
        {content.map((card, index) => (
          <div key={index} className="col-12 col-sm-3 RowIcon__card">
            <img
              src={card.icon}
              alt={card.title}
              className="RowIcon__card--img"
              loading="lazy"
            />
            <h3 className="RowIcon__card--title">
              {card.title}
            </h3>
            <p className="RowIcon__card--description">
              {card.subtitle}
            </p>
          </div>
        ))}
      </div>
      <div className="RowIcon__cta">
        <a href="/categorias" className="btn btn-primary">
          Ver categorías
        </a>
      </div>
    </div>
  );
};

export default MultiRowIcon;