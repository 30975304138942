import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../styles/components/SearchBar.scss';
import searchIcon from '../assets/icons/search.svg';

const API_URL = process.env.REACT_APP_API_URL;

const SearchBar = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [images, setImages] = useState({}); // { [reference]: thumbnailUrl }
  const [isLoading, setIsLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const searchBarRef = useRef(null);

  useEffect(() => {
    if (query.length < 2) {
      setResults([]);
      setShowResults(false);
      return;
    }

    const fetchResults = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/products/search`, {
          headers: { authorization: token },
          params: { query },
        });
        setResults(response.data);
        setShowResults(true);
      } catch (error) {
        console.error('Error fetching search results:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const delayDebounceFn = setTimeout(fetchResults, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [query, API_URL]);

  // Función para generar las URLs de las imágenes
  const generateImageUrls = (reference, imageCount, warehouse) => {
    const BASE_URL = process.env.REACT_APP_S3_BASE_URL;
    // Comparar en minúsculas y sin espacios para evitar problemas de coincidencia
    const isPromoOpcion =
      warehouse &&
      warehouse.toLowerCase().replace(/\s+/g, '') === 'promoopcion';
    const fixedReference = isPromoOpcion
      ? reference.replace(/\s+/g, '_')
      : reference;
    return {
      thumbnail1: `${BASE_URL}/thumbnails_mini/_ref_${fixedReference}_img_1_of_${imageCount}.jpg`,
      // Puedes generar otras imágenes si lo requieres
    };
  };

  // Efecto para actualizar las imágenes cuando cambian los resultados
  useEffect(() => {
    if (results.length > 0) {
      const newImages = {};
      results.forEach((product) => {
        if (product.reference) {
          const totalImages = product.image_count || 10; // Valor por defecto: 10
          // Generar URL usando la función, pasando también el warehouse del producto
          const { thumbnail1 } = generateImageUrls(
            product.reference,
            totalImages,
            product.warehouse
          );
          newImages[product.reference] = thumbnail1;
        }
      });
      setImages(newImages);
    }
  }, [results]);

  // Efecto para cerrar los resultados si se hace clic fuera del search bar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="search-bar" ref={searchBarRef}>
      <img src={searchIcon} alt="Buscar" className="search-bar__icon" />
      <input
        type="text"
        placeholder="Buscar productos..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {isLoading && <p>Cargando...</p>}
      {showResults && results.length > 0 && (
        <div className="search-results">
          {results.map((product) => {
            const thumbnailUrl = images[product.reference];

            return (
              <div key={product.id} className="search-result-item">
                {thumbnailUrl ? (
                  <img
                    src={thumbnailUrl}
                    alt={product.title}
                    className="SideCart__item-image"
                  />
                ) : (
                  <div className="SideCart__item-image--placeholder" />
                )}
                <a href={`/productos/${product.slug}`}>{product.title}</a>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
