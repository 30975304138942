import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { login } from '../services/authService';
import Slider from 'react-slick';

import ProductCard from './ProductCard';
import { PrevArrow, NextArrow } from '../components/CustomArrows';

import '../styles/components/ProductCarousel.scss';

const API_URL = process.env.REACT_APP_API_URL;

const ProductCarousel = ({ sectionTitle, sectionSubtitle }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const token = await login();
        const response = await axios.get(`${API_URL}/products/random-products`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products', error);
      }
    };

    fetchProducts();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    arrows: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const validProducts = products.filter(
    (product) => product && product.title && product.reference
  );

  return (
    <div className="ProductCarousel container General__container">
      <div class="ProductCarousel__header">
        <div class="ProductCarousel__header--text">
          <h2 className="ProductCarousel__title">
            {sectionTitle}
          </h2>
          <p className="ProductCarousel__subtitle">
            {sectionSubtitle}
          </p>
        </div>
        <a href="/productos/todos" className="btn btn-secondary">
          Ver productos
        </a>
      </div>
      {validProducts.length === 0 ? (
        <p>No products available</p>
      ) : (
        <Slider {...settings}>
          {validProducts.map((product, index) => (
            <div key={index} className="ProductCarousel__item">
              <ProductCard product={product} />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default ProductCarousel;
