import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductCard from '../components/ProductCard';
import '../styles/pages/CategoryProductsPage.scss';

import CategoryTags from '../components/CategoryTags';
import CategoryHeader from '../components/CategoryHeader';
import SortDropdown from '../components/SortDropdown';

const API_URL = process.env.REACT_APP_API_URL;

const CategoryProductsPage = () => {
  const { categoryName, subcategoryName } = useParams();
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortOrder, setSortOrder] = useState('');

  useEffect(() => {
    setProducts([]);
    setPage(1);
    setHasMore(true);
    fetchProducts(1, sortOrder);
  }, [categoryName, subcategoryName, sortOrder]);

  const fetchProducts = async (pageNumber, order = '') => {
    try {
      const token = localStorage.getItem('token');
      const endpoint = subcategoryName
        ? `${API_URL}/categories/${categoryName}/${subcategoryName}/products?page=${pageNumber}&limit=10&order=${order}`
        : `${API_URL}/categories/${categoryName}/products?page=${pageNumber}&limit=10&order=${order}`;

      const response = await axios.get(endpoint, {
        headers: {
          authorization: token,
        },
      });

      const { products: newProducts, totalPages } = response.data;
      // Filtrar productos vacíos (por ejemplo, sin título o sin referencia)
      const validProducts = newProducts.filter(
        (product) => product && product.reference && product.title
      );

      setProducts((prevProducts) => [...prevProducts, ...validProducts]);
      setHasMore(pageNumber < totalPages);
      setPage(pageNumber + 1);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleSortChange = (order) => {
    setSortOrder(order);
  };

  const getPageTitle = () => {
    if (products.length > 0) {
      const firstProduct = products[0];
      if (subcategoryName) {
        return firstProduct.Subcategories[0]?.name || subcategoryName;
      }
      return firstProduct.Categories[0]?.name || categoryName;
    }
    return subcategoryName || categoryName;
  };

  // Agrupar productos en subarrays de 4 elementos
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  // Filtrar nuevamente la lista completa antes de agrupar
  const filteredProducts = products.filter(
    (product) => product && product.reference && product.title
  );
  const productChunks = chunkArray(filteredProducts, 4);

  return (
    <div className="CategoryProductsPage container">
      <CategoryHeader
        title={getPageTitle()}
        subtitle="Descubre nuestra selección de productos cuidadosamente elegidos para brindarte la mejor combinación de funcionalidad y estilo. Ya sea que busques soluciones prácticas para tu hogar y estilo de vida, aquí encontrarás opciones de alta calidad y confiabilidad."
      />
      <CategoryTags />
      <div className="CategoryProductsPage__results row">
        <div className="col-12">
          <InfiniteScroll
            dataLength={filteredProducts.length}
            next={() => fetchProducts(page)}
            hasMore={hasMore}
            loader={<h4 className="Pagination__status">Cargando productos...</h4>}
            endMessage={<p className="Pagination__status">No hay más productos disponibles.</p>}
          >
            {productChunks.map((chunk, chunkIndex) => (
              <div key={chunkIndex} className="products-row">
                <div className="row">
                  {chunk.map((product, index) => (
                    <div key={`${product.id}-${index}`} className="col-12 col-md-3">
                      <ProductCard product={product} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default CategoryProductsPage;
