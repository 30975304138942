const loadCategoryIcon = (categorySlug) => {
  try {
   
    const context = require.context('../assets/icons', false, /\.(png|jpe?g|svg)$/);

  
    const imageKey = context.keys().find((filename) =>
      filename.includes(`${categorySlug.toLowerCase()}_tag`)
    );

    
    if (!imageKey) {
      console.error(`No image found for categorySlug: ${categorySlug}`);
      return null;
    }

 
    return context(imageKey); 
  } catch (error) {
    console.error('Error loading category icon:', error);
    return null;
  }
};

export default loadCategoryIcon;
