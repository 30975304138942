// Helper to load category images
const loadCategoryImages = () => {
  const images = {};
  const context = require.context('../assets/icons', false, /\.(png|jpe?g|svg)$/);

  context.keys().forEach((filename) => {
    const cleanedFilename = filename
      .replace('./', '') // Remove './' from the start
      .toLowerCase(); // Convert to lowercase

    images[cleanedFilename] = context(filename);
  });

  return images;
};

export default loadCategoryImages;
