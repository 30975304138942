import React from 'react';
import '../styles/components/TopBar.scss';

const TopBar = () => {
  return (
    <div className="Topbar">
      <p>
        Atención personalizada y envíos a toda Colombia 
        <span role="img" aria-label="delivery-truck"> 🚚</span>
        <span role="img" aria-label="package"> 📦</span>
      </p>
    </div>
  );
};

export default TopBar;
