import React from 'react';
import '../styles/components/ProductVariationsTable.scss';

const ProductVariationsTable = ({ variations, warehouse }) => {
  // Caso 2: Si la bodega es "CDO promocionales" o "PromoOpcion"
  if (warehouse === 'CDO promocionales' || warehouse === 'PromoOpcion' || warehouse === 'Boton Promo') {
    return (
      <div className="ProductVariationsTable">
        <h3 className="ProductVariationsTable__title">Existencias en-stock</h3>
        <p class="empty_inventory">
          Existencias no disponibles para este producto
        </p>
      </div>
    );
  }

  if (warehouse === 'Marpico') {
    return (
      <div className="ProductVariationsTable">
        <h3 className="ProductVariationsTable__title">Existencias en-stock</h3>
        <table className="ProductVariationsTable__table">
          <thead>
            <tr>
              <th>Variedad</th>
              <th>Codigo</th>
              <th>Celta</th>
              <th>Disponibles en Inventario</th>
              <th>Estado de Tránsito</th>
              <th>Unidades en tránsito</th>
              <th>Ingreso al Sistema</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(variations) &&
              variations.map((variation, index) => (
                <tr key={variation.Codigo || index}>
                  <td>{variation.Variedad}</td>
                  <td>{variation.Codigo}</td>
                  <td>{variation.Celta}</td>
                  <td>{variation['Disponibles en inventario'] || variation['Disponibles en Inventario']}</td>
                  <td>{variation['Estado de tránsito'] || variation['Estado de Tránsito']}</td>
                  <td>{variation['Unidades en tránsito'] || variation['Unidades en Tránsito']}</td>
                  <td>{variation['Ingreso al sistema'] || variation['Ingreso al Sistema']}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }

  // Caso 1 (por defecto): Para "Catalogos promocionales" u otras bodegas donde variations es un objeto
  return (
    <div className="ProductVariationsTable">
      <h3 className="ProductVariationsTable__title">Existencias en-stock</h3>
      <table className="ProductVariationsTable__table">
        <thead>
          <tr>
            <th>Color/Variación</th>
            <th>Total Disponible</th>
          </tr>
        </thead>
        <tbody>
          {variations &&
            typeof variations === 'object' &&
            !Array.isArray(variations) &&
            Object.entries(variations).map(([key, value]) => (
              <tr key={key}>
                <td>{key}</td>
                <td>{value}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductVariationsTable;
