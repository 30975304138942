import React from 'react';
import { FaSmile, FaShippingFast, FaTrophy, FaUsers } from 'react-icons/fa';
import '../styles/components/BenefitsRow.scss';

const BenefitsRow = () => {
  return (
    <section className="benefits-row container-fluid">
      <div className="benefit">
        <FaSmile className="benefit__icon" />
        <p className="benefit__text">98% de clientes satisfechos</p>
      </div>
      <div className="benefit">
        <FaShippingFast className="benefit__icon" />
        <p className="benefit__text">Recibe tu pedido en tiempo récord.</p>
      </div>
      <div className="benefit">
        <FaTrophy className="benefit__icon" />
        <p className="benefit__text">Mejor relación calidad/precio</p>
      </div>
      <div className="benefit">
        <FaUsers className="benefit__icon" />
        <p className="benefit__text">+10.000 clientes contentos</p>
      </div>
    </section>
  );
};

export default BenefitsRow;
