import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { CartProvider } from './context/CartContext'; // Asegúrate de que está importado

import Home from './pages/Home';
import CategoriesPage from './pages/CategoriesPage';
import CategoryProductsPage from './pages/CategoryProductsPage';
import AllProductsPage from './pages/AllProductsPage';
import ProductPage from './pages/ProductPage';
import LoginPage from './pages/LoginPage';
import SideCart from './components/SideCart';

const App = () => {
  return (
    <CartProvider> 
      <Router>
        <Header />
        <SideCart /> 
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/79834yhbdgyt562xa" element={<LoginPage />} />
          <Route path="/categorias" element={<CategoriesPage />} />
          <Route path="/categorias/:categoryName" element={<CategoryProductsPage />} />
          <Route path="/categorias/:categoryName/:subcategoryName" element={<CategoryProductsPage />} />
          <Route path="/productos/todos" element={<AllProductsPage />} />
          <Route path="/productos/:slug" element={<ProductPage />} />
        </Routes>
        <Footer />
      </Router>
    </CartProvider>
  );
};

export default App;
