// src/pages/AllProductsPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductCard from '../components/ProductCard';
import CategoryHeader from '../components/CategoryHeader';
import '../styles/pages/AllProductsPage.scss';

const API_URL = process.env.REACT_APP_API_URL;

const AllProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // Cargar productos al montar la página y cada vez que se solicitan nuevos datos
  useEffect(() => {
    // Reiniciar al cargar la página
    setProducts([]);
    setPage(1);
    setHasMore(true);
    fetchProducts(1);
  }, []);

  const fetchProducts = async (pageNumber) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/products/all`, {
        headers: { authorization: token },
        params: { page: pageNumber, limit: 10 }
      });
      const { products: newProducts, totalPages } = response.data;
      // Filtrar productos válidos: aquellos que tengan título y referencia
      const validProducts = newProducts.filter(
        (product) => product && product.title && product.reference
      );
      setProducts((prev) => [...prev, ...validProducts]);
      setHasMore(pageNumber < totalPages);
      setPage(pageNumber + 1);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  // Filtrar globalmente la lista de productos para renderizar solo los válidos
  const validProducts = products.filter(
    (product) => product && product.title && product.reference
  );

  return (
    <div className="AllProductsPage container">
      <CategoryHeader
        title="Todos los productos"
        subtitle="Descubre nuestra selección de productos cuidadosamente elegidos para brindarte la mejor combinación de funcionalidad y estilo. Ya sea que busques soluciones prácticas para tu hogar y estilo de vida, aquí encontrarás opciones de alta calidad y confiabilidad."
      />
      <InfiniteScroll
        dataLength={validProducts.length}
        next={() => fetchProducts(page)}
        hasMore={hasMore}
        loader={<h4 className="Pagination__status">Cargando productos...</h4>}
        endMessage={<p className="Pagination__status">No hay más productos disponibles.</p>}
      >
        <div className="row">
          {validProducts.map((product) => (
            <div key={product.id} className="col-12 col-md-3 products__row">
              <ProductCard product={product} />
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default AllProductsPage;
