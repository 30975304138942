import React from 'react';
import '../styles/components/Button.scss';

const Button = ({ text, className = 'btn-primary', type = 'button', onClick, href }) => {
  if (type === 'link' && href) {
    return (
      <a href={href} className={className}>
        {text}
      </a>
    );
  }

  return (
    <button className={className} type={type} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
