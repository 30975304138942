import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const USER = process.env.REACT_APP_USER
const PASS = process.env.REACT_APP_PASS

export const login = async () => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, {
      username: USER,
      password: PASS
    });
    const token = response.data.token;
    localStorage.setItem('token', token); 
    return token;
  } catch (error) {
    console.error('Error during login', error);
  }
};
