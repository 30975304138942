import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import loadCategoryIcon from '../utils/loadCategoryIcon';
import axios from 'axios';
import '../styles/components/CategoryTags.scss';

const API_URL = process.env.REACT_APP_API_URL;

const CategoryTags = () => {
  const { categoryName } = useParams();
  const [categories, setCategories] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/categories`, {
          headers: {
            authorization: token,
          },
        });
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const displayedCategories = showAll ? categories : categories.slice(0, 10); 

  return (
    <div className="CategoryTags">
      {displayedCategories.map((category) => (
        <Link
          to={`/categorias/${category.slug}`}
          key={category.id}
          className={`CategoryTag ${category.slug === categoryName ? 'active' : ''}`}
        >
          <img src={loadCategoryIcon(category.slug)} alt={category.name} />
          {category.name}
        </Link>
      ))}
      <button onClick={() => setShowAll(!showAll)} className="CategoryTags__toggle">
        {showAll ? 'Mostrar menos' : 'Mostrar más'}
      </button>
    </div>
  );
};

export default CategoryTags;
