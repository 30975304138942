import React from 'react';
import '../styles/components/MasonryGrid.scss';

//Images
import barVino from '../assets/slider/bar_vino.webp';
import bicicleta from '../assets/slider/bicicleta.webp';
import cuidado from '../assets/slider/cuidado_personal.webp';
import herramientas from '../assets/slider/herramientas.webp';
import escritura from '../assets/slider/escritura.webp';
import hogar from '../assets/slider/hogar.webp';
import llaveros from '../assets/slider/llaveros.webp';
import mugs from '../assets/slider/mugs.webp';
import oficina from '../assets/slider/oficina.webp';
import paraguas from '../assets/slider/paraguas.webp';

const categories = [
  {
    name: 'Bar y vino',
    subtitle: 'Destapadores, sets, copas',
    image: barVino,
    url: '/categorias/bar-y-vino',
  },
  {
    name: 'Bicicleta',
    subtitle: 'Toallas, sets, morrales',
    image: bicicleta,
    url: '/categorias/bicicleta',
  },
  {
    name: 'Cuidado personal',
    subtitle: 'Gel, esterilizadores, antibacteriales',
    image: cuidado,
    url: '/categorias/pharma-y-cuidado-personal',
  },
  {
    name: 'Herramientas',
    subtitle: 'Candados, metros, sets',
    image: herramientas,
    url: '/categorias/herramientas',
  },
  {
    name: 'Escritura',
    subtitle: 'Lapices, estuches, portaminas, bolígrafos',
    image: escritura,
    url: '/categorias/articulos-de-escritura',
  },
  {
    name: 'Hogar',
    subtitle: 'Bar, artículos cocina, portacomidas',
    image: hogar,
    url: '/categorias/hogar',
  },
  {
    name: 'LLaveros',
    subtitle: 'Con luz, metálicos, especiales',
    image: llaveros,
    url: '/categorias/llaveros',
  },
  {
    name: 'Mugs y bebidas',
    subtitle: 'Botilitos, mugs, termos',
    image: mugs,
    url: '/categorias/mugs-botilitos-vasos-y-termos',
  },
  {
    name: 'Artículos de oficina',
    subtitle: 'Libretas, artículos escritorio, tarjeteros',
    image: oficina,
    url: '/categorias/oficina',
  },
  {
    name: 'Paraguas',
    subtitle: 'Golf, lujo. impermeables',
    image: paraguas,
    url: '/categorias/paraguas-e-impermeables',
  },
];

const MasonryGrid = () => {
  return (
    <div className="MasonryGrid container-fluid">
      <svg class="MasonryGrid__wave-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fbf9f9" fill-opacity="1" d="M0,32L48,48C96,64,192,96,288,112C384,128,480,128,576,144C672,160,768,192,864,176C960,160,1056,96,1152,90.7C1248,85,1344,139,1392,165.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
      <h2 className="MasonryGrid__title">Explora Nuestras Categorías</h2>
      <p className="MasonryGrid__subtitle">
        Descubre una amplia variedad de productos cuidadosamente seleccionados para cada necesidad.
        Encuentra inspiración y calidad en nuestras categorías más populares.
      </p>
      <div className="masonry">
        {categories.slice(0, 10).map((category, index) => (
          <div key={index} className={`masonry__item masonry__item--${index % 5}`}>
            <a href={category.url} className="masonry__link">
              <img
                src={category.image}
                alt={category.name}
                className="masonry__image"
                loading="lazy"
              />
              <div className="masonry__overlay">
                <h2 className="masonry__title">{category.name}</h2>
                <p className="masonry__subtitle">{category.subtitle}</p>
              </div>
            </a>
          </div>
        ))}
      </div>

      <div className="MasonryGrid__cta">
        <a href="/categorias" className="btn btn-primary">
          Ver categorías
        </a>
      </div>
    </div>
  );
};

export default MasonryGrid;
