import React from 'react';
import leftArrowIcon from '../assets/icons/chevron-left.svg'; 
import rightArrowIcon from '../assets/icons/chevron-right.svg';

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        backgroundImage: `url(${leftArrowIcon})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '40px',
        height: '40px',
        zIndex: 10,
        fontSize: '2rem',
        color: 'white',
        backgroundColor: 'white',
        borderRadius: '50%',
        lineHeight: '40px',
        textAlign: 'center',
        cursor: 'pointer',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: '30px',
      }}
      onClick={onClick}
    />
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        backgroundImage: `url(${rightArrowIcon})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '40px',
        height: '40px',
        zIndex: 10,
        fontSize: '2rem',
        color: 'white',
        backgroundColor: 'white',
        borderRadius: '50%',
        lineHeight: '40px',
        textAlign: 'center',
        cursor: 'pointer',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right:'30px',
      }}
      onClick={onClick}
    />
  );
};

export { PrevArrow, NextArrow };
