const validateQuotationForm = (formData) => {
  const errors = {};

  if (!formData.name.trim()) errors.name = 'Nombre es requerido';
  
  if (!formData.email.trim()) {
    errors.email = 'Correo electrónico es requerido';
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    errors.email = 'Formato de correo inválido';
  }
  
  if (!formData.phone.trim()) errors.phone = 'Teléfono es requerido';
  if (!formData.company.trim()) errors.company = 'Empresa es requerido';
  if (!formData.budget.trim()) errors.budget = 'Presupuesto es requerido';

  return errors;
};

export default validateQuotationForm;
