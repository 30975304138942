import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useAuth from '../utils/useAuth';
import enStockLogo from '../assets/logos/logo.svg'; // Importamos el logo
import defaultImage from '../assets/images/image-not-found.png';

import { truncateString } from '../utils/truncate';

import '../styles/components/ProductCard.scss';

const ProductCard = ({ product }) => {
  if (!product || !product.image_count || product.image_count <= 0) {
    return null;
  }

  const [thumbnail1, setThumbnail1] = useState(null);
  const [thumbnail2, setThumbnail2] = useState(null);
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const generateImageUrls = (reference, imageCount, warehouse) => {
    const BASE_URL = process.env.REACT_APP_S3_BASE_URL;
    const fixedReference =
      warehouse === 'PromoOpcion' ? reference.replace(/\s+/g, '_') : reference;
    return {
      thumbnail1: `${BASE_URL}/thumbnails/_ref_${fixedReference}_img_1_of_${imageCount}.jpg`,
      thumbnail2: `${BASE_URL}/thumbnails/_ref_${fixedReference}_img_2_of_${imageCount}.jpg`,
    };
  };

  useEffect(() => {
    if (product && product.reference && product.image_count) {
      const { thumbnail1, thumbnail2 } = generateImageUrls(
        product.reference,
        product.image_count,
        product.warehouse
      );
      setThumbnail1(thumbnail1);
      setThumbnail2(thumbnail2);
    } else {
      setThumbnail1(defaultImage);
      setThumbnail2(defaultImage);
    }
  }, [product.reference, product.image_count, product.warehouse]);

  const hoverImage = thumbnail2 || thumbnail1;

  const handleScrollToTop = () => {
    if (location.pathname.startsWith('/productos')) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div className="ProductCard">
      {/* Imagen principal con hover */}
      <div className="ProductCard__image">
        <Link to={`/productos/${product.slug}`}>
          <div className="ProductCard__image-wrapper">
            <img
              src={thumbnail1}
              alt={product.title || 'Imagen por defecto'}
              className="ProductCard__img"
              onClick={handleScrollToTop}
              onMouseOver={(e) => (e.currentTarget.src = hoverImage)}
              onMouseOut={(e) => (e.currentTarget.src = thumbnail1)}
            />
            <img
              src={enStockLogo}
              alt="Logo del sitio"
              className="ProductCard__logo"
              loading="lazy"
            />
          </div>
        </Link>
      </div>

      <Link to={`/productos/${product.slug}`} onClick={handleScrollToTop}>
        <h3 className="ProductCard__title">
          {truncateString(product.title, 40)}
        </h3>
      </Link>

      {/* Footer con botón y bodega */}
      <div className="ProductCard__footer">
        <Link
          to={`/productos/${product.slug}`}
          onClick={handleScrollToTop}
          className="btn btn-primary"
        >
          Cotizar
        </Link>

        {isAuthenticated && (
          <p className="ProductCard__warehouse">
            <span className="ProductCard__warehouse--title">Bodega:</span>
            <span className="ProductCard__warehouse--value">
              <a href={product.warehouseUrl} rel="noopener noreferrer" target="_blank">
                {product.warehouse}
              </a>
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
