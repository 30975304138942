import React from 'react';
import '../styles/components/Footer.scss';

//Footer images
import Logo from '../assets/logos/logo-enstock-blanco.svg';
import Payment from '../assets/images/pago-bold-footer.png';
import Email from '../assets/icons/email.svg';
import Phone from '../assets/icons/phone.svg';

const Footer = () => {
  return (
    <footer className="Footer container-fluid">
      <div className="row">
        <div className="Footer__logo col-12 col-md-2 offset-md-5">
          <img
            src={Logo}
            alt='Logo promocionales en-stock blanco'
          />
        </div>
      </div>
      <div className="row">
        <div className="Footer__info col-12 col-md-3">
          <h3 className="Footer__info--title">
            Acerca de En - stock
          </h3>
          <p className="Footer__info--text">
            En-Stock es una plataforma especializado en la venta de productos promocionales 
            personalizados. Ofrecemos una amplia gama de artículos, como bolígrafos, vasos y 
            tecnología, con diversas técnicas de marcación para garantizar un acabado 
            profesional que resalte la identidad de tu marca.
          </p>
        </div>
        <div className="Footer__info center col-12 col-md-4">
          <h3 className="Footer__info--title">
            Contáctenos
          </h3>
          <div className="Footer__info--head">
            <img
              src={Email}
              alt="Icono Email"
            />
            <span className="title-thin">
              Correo cotizaciones
            </span>
          </div>
          <span className="Footer__info--contact">
            <a href="mailto:ventas@en-stock.com">
              ventas@en-stock.com
            </a> 
          </span>
          <div className="Footer__info--head">
            <img
              src={Phone}
              alt="Icono Email"
            />
            <span className="title-thin">
              Teléfonos contacto
            </span>
          </div>
          <span className="Footer__info--contact">
            <a href="tel:3028593318">
                302 859 3318
            </a>
               -   
            <a href="tel:3005572000">
                300 557 2000
            </a>
          </span>
        </div>
        <div className="Footer__info col-12 col-md-5">
          <h3 className="Footer__info--title">
            Paga fácil y seguro desde
          </h3>
          <div className="Footer__info--payment">
          <img
            src={Payment}
            alt="Aceptamos todos los medios de pago"
          />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="Footer__copyright col-12">
          <span>
            © Promocionalesenstock™ 2025. Derechos Reservados.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;