import React from 'react';
import TopBar from '../components/TopBar';
import HeroSlider from '../components/HeroSlider';
import BenefitsRow from '../components/BenefitsRow';
import MultiRowIcon from '../components/MultiRowIcon';
import MarqueeText from '../components/MarqueeText';
import DualSectionSlider from '../components/DualSectionSlider';
import ProductCarousel from '../components/ProductCarousel';
import SimpleBanner from '../components/SimpleBanner';
import MasonryGrid from '../components/MasonryGrid';


import sendEmailIcon from '../assets/icons/send-email.svg';
import selectProductIcon from '../assets/icons/select-product.svg';
import formIcon from '../assets/icons/form.svg';

import slide1 from '../assets/slider/sublimacion.png';
import slide2 from '../assets/slider/serigrafia.png';
import slide3 from '../assets/slider/screen.png';
import slide4 from '../assets/slider/laser.png';
import slide5 from '../assets/slider/bordado.png';

const multiRowData = [
  {
    title:'Selecciona tu producto y cantidades',
    subtitle:'Escoge el producto que deseas personalizar y ajusta la cantidad según tus necesidades. Puedes explorar nuestro catálogo para encontrar los artículos que mejor se adapten a tu proyecto.',
    icon:selectProductIcon
  },
  {
    title:'Personaliza tu solicitud de compra',
    subtitle:'Incorpora los detalles de personalización y las especificaciones de tu pedido. Completa el formulario con las características especiales que deseas para tu producto y proporciona tu información personal.',
    icon:formIcon
  },
  {
    title:'Envía tu solicitud de cotización',
    subtitle:'Una vez que hayas completado los detalles de tu pedido, envía tu solicitud de cotización. Nuestro equipo revisará tu solicitud y se pondrá en contacto contigo con la información y precios detallados.',
    icon:sendEmailIcon
  }
]

const dualSlidesData = [
  {
    miniTitle: 'Nuestras técnicas de marcación',
    heading: 'Sublimación',
    description: 'La sublimación es una técnica de impresión que utiliza calor para transferir una imagen a un material, generalmente poliéster o productos con un recubrimiento especial. Es ideal para tazas, textiles y artículos que necesitan imágenes a todo color de alta calidad y durabilidad.',
    img: slide1
  },
  {
    miniTitle: 'Nuestras técnicas de marcación',
    heading: 'Serigrafía',
    description: 'La serigrafía es un método de impresión que utiliza una malla tensada sobre un marco para transferir tinta a un objeto. Es ideal para artículos promocionales como camisetas, esferos y bolsas, y permite imprimir diseños de uno o varios colores de manera precisa y económica.',
    img: slide2
  },
  {
    miniTitle: 'Nuestras técnicas de marcación',
    heading: 'Screen Printing',
    description: 'La impresión en pantalla (o serigrafía) es una variante que implica el uso de una plantilla o malla para aplicar tinta sobre un objeto. Es muy versátil y se puede usar en una variedad de superficies, incluyendo plástico, metal y textiles. Es una técnica muy popular para la personalización de esferos, camisetas y objetos planos.',
    img: slide3
  },
  {
    miniTitle: 'Nuestras técnicas de marcación',
    heading: 'Láser',
    description: 'El grabado láser utiliza un rayo de luz altamente concentrado para marcar la superficie de un objeto con precisión. Es perfecto para artículos de metal, madera, cristal y algunos plásticos. Esta técnica ofrece un acabado limpio, permanente y resistente al desgaste.',
    img: slide4
  },
  {
    miniTitle: 'Nuestras técnicas de marcación',
    heading: 'Bordado',
    description: 'El bordado es un proceso de costura de un diseño en telas o prendas utilizando hilos de diferentes colores. Es ideal para textiles como gorras, camisetas y bolsas, y proporciona un acabado elegante y duradero. Es muy apreciado por su resistencia al lavado y su aspecto de alta calidad.',
    img: slide5
  }
];

const Home = () => {
  return (
    <div>
      <TopBar/>
      <HeroSlider/>
      <BenefitsRow/>
      <MarqueeText
        texts={['💥 ¡OFERTAS IMPERDIBLES! 💥 → Desde 300 pesos','STOCK LIMITADO, NO LO DEJES PASAR','⚡ DESCUENTOS EXCLUSIVOS ⚡']}
      />
      <ProductCarousel
        sectionTitle="Los más vendidos!"
        sectionSubtitle="Elija de nuestra enorme colección personalizable de bolígrafos, vasos y botellas, tecnología y más."
      />
      <SimpleBanner
        totalProducts="4.160"
        totalColors="10.116"
      >
      </SimpleBanner>
      <ProductCarousel
        sectionTitle="¡Novedades Imperdibles!"
        sectionSubtitle="Descubre nuestra más reciente colección de bolígrafos, vasos, botellas, tecnología y mucho más, listos para personalizar."
      />
      <MasonryGrid/>
      <DualSectionSlider
        title="Nuestras técnicas de marcación"
        slides={dualSlidesData}
      />
      <MultiRowIcon
        sectionTitle="¡Encuentra el producto perfecto para ti!"
        sectionSubtitle="Elige entre nuestra enorme colección personalizable de bolígrafos, vasos, botellas, tecnología y mucho más."
        content={multiRowData}
      />
      <BenefitsRow/>
    </div>
    
  );
};

export default Home;