import React, { useState, useCallback } from 'react';
import '../styles/components/QuantitySelector.scss';
import debounce from 'lodash/debounce';

const QuantitySelector = React.memo(({ min = 100, max = 500, onQuantityChange }) => {
  const initialQuantity = 1;
  // Guardamos el valor en formato string para permitir que el usuario lo borre.
  const [inputValue, setInputValue] = useState(String(initialQuantity));

  // Creamos una función debounced para llamar a onQuantityChange cuando el usuario escriba un número válido.
  const debouncedOnQuantityChange = useCallback(
    debounce((newQuantity) => {
      onQuantityChange(newQuantity);
    }, 300),
    [onQuantityChange]
  );

  // Función para disminuir la cantidad.
  const handleDecrease = () => {
    const currentNumber = parseInt(inputValue, 10);
    let newQuantity = isNaN(currentNumber) ? min : currentNumber - 1;
    if (newQuantity < min) {
      newQuantity = min;
    }
    setInputValue(String(newQuantity));
    debouncedOnQuantityChange(newQuantity);
  };

  // Función para aumentar la cantidad.
  const handleIncrease = () => {
    const currentNumber = parseInt(inputValue, 10);
    let newQuantity = isNaN(currentNumber) ? min : currentNumber + 1;
    if (newQuantity > max) {
      newQuantity = max;
    }
    setInputValue(String(newQuantity));
    debouncedOnQuantityChange(newQuantity);
  };

  // Permite escribir en el input. Se acepta un valor vacío.
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Si el valor es un número, y está dentro del rango, se llama al callback.
    const num = parseInt(value, 10);
    if (!isNaN(num)) {
      if (num >= min && num <= max) {
        debouncedOnQuantityChange(num);
      }
    }
  };

  // Al salir del input, se valida que el valor sea un número válido y que no sea menor que el mínimo.
  // Si no, se corrige el valor al mínimo (o al máximo, si supera ese límite).
  const handleBlur = () => {
    let num = parseInt(inputValue, 10);
    if (isNaN(num) || num < min) {
      num = min;
      setInputValue(String(num));
      onQuantityChange(num); // Se llama inmediatamente al callback.
    } else if (num > max) {
      num = max;
      setInputValue(String(num));
      onQuantityChange(num);
    }
  };

  return (
    <div className="QuantitySelector">
      <h3 class="h6">Selecciona una cantidad</h3>
      <div className="QuantitySelector__controls">
        <button 
          className="QuantitySelector__button" 
          onClick={handleDecrease}
          aria-label="Disminuir cantidad"
        >
          –
        </button>
        <input 
          type="number"
          className="QuantitySelector__input"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          min={min}
          max={max}
        />
        <button 
          className="QuantitySelector__button" 
          onClick={handleIncrease}
          aria-label="Aumentar cantidad"
        >
          +
        </button>
      </div>
    </div>
  );
});

export default QuantitySelector;
