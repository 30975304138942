import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaSearchPlus } from 'react-icons/fa';
import '../styles/components/ProductImageCarousel.scss';

Modal.setAppElement('#root');

/**
 * ProductImageCarousel component displays product images passed as props.
 * It shows thumbnails and a selected full-size image. Clicking on the main image
 * or the zoom button opens a modal with the enlarged image.
 */
const ProductImageCarousel = ({ productReference, thumbnails = [], fullSize = [] }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Set the initial selected image after thumbnails/fullSize update
    if (fullSize.length > 0) {
      setSelectedImage(fullSize[0]);
    } else {
      setSelectedImage(null);
    }
  }, [fullSize]);

  /**
   * Handle the click on a thumbnail: sets the selected full-size image
   * based on the clicked thumbnail index.
   * @param {number} index - The index of the chosen thumbnail.
   */
  const handleThumbnailClick = (index) => {
    setSelectedImage(fullSize[index]);
  };

  /**
   * Opens the modal to show the enlarged version of the selected image.
   */
  const openModal = () => {
    setIsModalOpen(true);
  };

  /**
   * Closes the modal.
   */
  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (!thumbnails.length && !fullSize.length) {
    return <p>No se encontraron imágenes para este producto.</p>;
  }

  return (
    <div className="ProductImageCarousel">
      <div className="ProductImageCarousel__thumbnails">
        <div className="ProductImageCarousel__thumbnail-slider">
          {thumbnails.map((thumbnail, index) => (
            <img
              key={index}
              src={thumbnail}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => handleThumbnailClick(index)}
              className="ProductImageCarousel__thumbnail"
            />
          ))}
        </div>
      </div>

      <div className="ProductImageCarousel__main-image-container">
        {selectedImage && (
          <>
            <img
              src={selectedImage}
              alt="Selected Product"
              className="ProductImageCarousel__main-image"
              onClick={openModal}
            />
            <button
              className="ProductImageCarousel__zoom-button"
              onClick={openModal}
              aria-label="Zoom image"
            >
              <FaSearchPlus />
            </button>
          </>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Zoomed Image"
        className="ProductImageCarousel__modal"
        overlayClassName="ProductImageCarousel__overlay"
      >
        <div className="ProductImageCarousel__modal-content">
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Zoomed Product"
              className="ProductImageCarousel__zoomed-image"
            />
          )}
          <button
            onClick={closeModal}
            className="ProductImageCarousel__close-button"
            aria-label="Close zoomed image"
          >
            &times;
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ProductImageCarousel;
