import React, { useState } from 'react';
import '../styles/components/Faq.scss';
import downArrow from '../assets/icons/downArrow.svg';

const Faq = ({ faqs }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="FAQ">
      <h2 className="FAQ__title">Preguntas frecuentes</h2>
      {faqs.map((faq, index) => (
        <div key={index} className="FAQ__item">
          <div
            className={`FAQ__question ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleFAQ(index)}
          >
            <span>{faq.question}</span>
            <img
              src={downArrow}
              alt="Arrow"
              className={`FAQ__icon ${activeIndex === index ? 'rotated' : ''}`}
            />
          </div>
          {activeIndex === index && (
            <div className="FAQ__answer">
              <p>{faq.answer}</p>
            </div>
          )}
          <hr className="FAQ__divider" />
        </div>
      ))}
    </div>
  );
};

export default Faq;
