import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../styles/pages/CategoryPage.scss';
import loadCategoryImages from '../utils/loadCategoriesImages';
import CategoryHeader from '../components/CategoryHeader';



const API_URL = process.env.REACT_APP_API_URL;

const normalizeSlug = (slug) => {
  return slug.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
};

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const categoryImages = loadCategoryImages();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/categories`, {
          headers: {
            authorization: token,
          },
        });
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories', error);
      }
    };

    fetchCategories();
  }, []);

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const categoryChunks = chunkArray(categories, 4);

  return (
    <div className="CategoriesPage container">
      <CategoryHeader
        title="Categorías"
        subtitle="Explora nuestra amplia selección de productos diseñados para satisfacer tus necesidades en cada aspecto de tu vida. Desde artículos para el hogar y herramientas prácticas, hasta tecnología innovadora y soluciones de cuidado personal, en nuestra tienda encontrarás
            todo lo que necesitas para facilitar tu día a día con calidad y estilo."
      />
      {categoryChunks.map((chunk, index) => (
        <div key={index} className="categories-row">
          <div className="row">
            {chunk.map((category) => {
              const imageKey = `${normalizeSlug(category.slug)}.svg`;
              const categoryImage = categoryImages[imageKey];

              return (
                <div key={category.id} className="category-item col-12 col-md-3">
                  {categoryImage ? (
                    <img
                      src={categoryImage.default || categoryImage}
                      alt={category.name}
                      className="category-icon"
                      loading="lazy"
                    />
                  ) : (
                    <div className="category-icon-placeholder">No Image</div>
                  )}
                  <div className="categories-list-links">
                    <h2>{category.name}</h2>
                    <ul>
                      {category.subcategories.map((subcategory) => (
                        <li key={subcategory.id}>
                          <Link
                            to={`/categorias/${category.slug}/${subcategory.slug}`}
                            className={
                              subcategory.name.toLowerCase() === 'todos'
                                ? 'subcategory-todos'
                                : ''
                            }
                          >
                            {subcategory.name}
                          </Link>
                        </li>
                      ))}
                      <li>
                        <Link to={`/categorias/${category.slug}`}>Todos</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CategoriesPage;
