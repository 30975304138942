import React, { useState, useEffect } from 'react';
import enStockLogo from '../assets/logos/logo.svg';
import userIcon from '../assets/icons/user.svg';
import cart from '../assets/icons/cart.svg';
import wpIcon from '../assets/icons/wp.svg';
import emailIcon from '../assets/icons/email.svg';
import HeaderDesktop from "./HeaderDesktop";
import HeaderResponsive from './HeaderResponsive';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);

  const toogleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) { 
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`header ${isSticky ? 'header--sticky' : ''}`}>
      <HeaderResponsive 
        logo={enStockLogo}
        userIcon={userIcon}
        shopIcon={cart}
        wpIcon={wpIcon}
        emailIcon={emailIcon}
      />
      <HeaderDesktop
        logo={enStockLogo}
        userIcon={userIcon}
        shopIcon={cart}
        wpIcon={wpIcon}
        emailIcon={emailIcon}
      />
    </header>      
  );
};

export default Header;
