import React from 'react';
import Slider from 'react-slick';
import '../styles/components/DualSectionSlider.scss';

const DualSectionSlider = ({ title, slides }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  return (
    <div className="DualSlider container General__container">
      <h2 className="DualSlider__title General__title">
        {title}
      </h2>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`DualSlider__slide ${index % 2 === 0 ? 'DualSlider__slide--even' : 'DualSlider__slide--odd'}`}
          >
            <div className="row">
              <div className="col-12 col-md-6 DualSlider__slide--text">
                <span className="short--text">
                  {slide.miniTitle}
                </span>
                <h3 className="h3">
                  {slide.heading}
                </h3>
                <p>
                  {slide.description}
                </p>
              </div>
              <div className="col-12 col-md-6 DualSlider__slide--img">
                <img
                  src={slide.img}
                  alt={`Slide ${slide.heading}`}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default DualSectionSlider;
